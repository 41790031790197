<template>
  <vx-card style="padding-left: 10px;">
    <vs-prompt
      title="Export as CSV"
      class="export-options"
      @cancel="clearFields"
      @accept="exportToCsv"
      accept-text="Export"
      @close="clearFields"
      :active.sync="activePrompt">
      <vs-input
        v-model="fileName"
        placeholder="Enter File Name.."
        class="w-full"
      />
    </vs-prompt>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="logs"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <vs-button
            type="gradient"
            size="small"
            icon-pack="feather"
            class="ml-1"
            icon="icon-download"
            color="danger"
            @click="exportCsvLogs">
            Export
          </vs-button>
               
          <div class="flex">
            <v-select
              v-model="dataTableParams.limit"
              class="mr-4"
              :options="limitOptions"
              :clearable="false"
            />
            <div class="flex  flex-end">
              <vs-input icon-pack="feather" icon="icon-search" placeholder="Search" v-model="dataTableParams.search" class="w-full"/>
            </div>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="firstName">Enquirer Name</vs-th>
          <vs-th sort-key="firstName">Enquirer Email</vs-th>
          <vs-th sort-key="name">Car Owner</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th sort-key="type">Type</vs-th>
          <vs-th sort-key="carId.make">Make</vs-th>
          <vs-th sort-key="carId.model"> Model</vs-th>
          <vs-th sort-key="carId.year">Year</vs-th>
          <vs-th sort-key="action" size="25%">Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].name">
              {{ data[indextr].name ? data[indextr].name : "N/A" }}
            </vs-td>
            <vs-td :data="data[indextr].email">
              {{ data[indextr].email ? data[indextr].email : "N/A" }}
            </vs-td>
            <vs-td :data="data[indextr].dealerId ? data[indextr].dealerId.name : null ">{{ data[indextr].dealerId ? data[indextr].dealerId.name : "-" }}</vs-td>
            <vs-td :data="data[indextr].createdAt">{{
              moment(data[indextr].createdAt).format("DD/MM/YYYY")
            }}</vs-td>
            <vs-td :data="data[indextr].type">
              {{ data[indextr].type }}
            </vs-td>
            <vs-td :data="data[indextr].carId? data[indextr].carId.make : null">
              {{ data[indextr].carId? data[indextr].carId.make :"-"}}
            </vs-td>
            <vs-td :data="data[indextr].carId? data[indextr].carId.model : null">
              {{ data[indextr].carId? data[indextr].carId.model :"-" }}
            </vs-td>
            <vs-td :data="data[indextr].carId? data[indextr].carId.year : null">
              {{ data[indextr].carId? data[indextr].carId.year :"-" }}
            </vs-td>
            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex" vs-justify="space-between">
             
                <vx-tooltip text="View" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="viewDetailHandler(data[indextr]._id)"
                    icon="icon-info"
                    color="success"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Delete" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="confirmDeleteRecord(data[indextr], indextr)"
                    icon="icon-trash"
                    color="danger"
                  ></vs-button>
                </vx-tooltip>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2">{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }}
        -
        {{
          totalDocs - currentPage * dataTableParams.limit > 0
            ? currentPage * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}</span
      >
      <vs-pagination
        :total="totalPage"
        v-model="dataTableParams.page"
      ></vs-pagination>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: this.$store.getters.logPageSearch,
        sort: "createdAt",
        dir: "desc",
        page: this.$store.getters.logPage,
        limit: this.$store.getters.logPageLimit,
      },
      logs: [],
      limitOptions: [5, 10, 25, 50, 100],
      selectedDealer: {},
      activePrompt: false,
      fileName: "",
      cellAutoWidth: true,
      selectedFormat: "csv",
      exportLogs: false,
      labels:["Type", "Created Date", "Make", "Model", "Year", "Series", "Badge", "Body Type", "Cylinder", "Transmission", "Engine Capacity", "Asking Price", "Kilometres", "Drive Type", "Buyer Name", "Buyer Email", "Buyer Phone", "Dealer Name", "Dealer Email", "Dealer Mobile", "Dealer Telephone", "Dealer Address", "License Number", "Stock Number","Dealer Id"],
      fieldsCsv: ["type", "createdAt", "make", "model", "year", "series", "badge", "bodyType", "cylinder", "transmission", "engineCapacity", "askingPrice", "kilometer", "drv", "buyerName", "buyerEmail", "buyerPhone", "ownerName", "ownerEmail", "ownerMobile", "ownerTelephone", "ownerAddress", "ownerLicenseNumber", "stockNumber","ownerId"],
    };
  },
  methods: {
    ...mapActions("logLead", ["fetchLogs", "deleteLog"]),
    ...mapActions(["updateLogPage", "initToFirstPage", "updateLogPageLimit", "updateLogPageSearch"]),
    moment(date) {
      return moment(date);
    },
    getLogList() {
      let self = this;
      this.fetchLogs(self.dataTableParams).then((res) => {
        self.logs = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.currentPage = self.page;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getLogList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getLogList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getLogList();
    },
    viewDetailHandler(id) {
      this.$router.push({ name: "LogLeadDetails", params: { id: id } });
    },
    exportCsvLogs() {
      this.activePrompt = true;
    },

    confirmDeleteRecord(data, i) {
      this.selectedLog = data;
      this.selectedIndex = i;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `You are about to delete log of "${data.name}"`,
        accept: this.deleteLogDetail,
        acceptText: "Delete",
      });
    },

    deleteLogDetail() {
      this.deleteLog(this.selectedLog._id).then((res) => {
        this.$vs.notify({
          title: "Log Deleted",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.logs.splice(this.selectedIndex, 1);
        this.totalDocs -= 1;
      });
    },
    exportToCsv() {
      import("@/vendor/Export2Excel").then((excel) => {
        const json =  this.csvData;
        const data = this.formatJson(this.fieldsCsv, json);
        excel.export_json_to_excel({
          header: this.labels,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat,
        });
        this.clearFields();
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          // Add col name which needs to be translated
          if (j === "images") {
            v[j] = v[j].replace("http", "\nhttp");
            return v[j];
          }
          else if (j === "updatedAt"){
            v[j] = moment(v[j]).format("DD/MM/YYYY")
            return v[j]
          }
          else if (j === "registrationExpiryDate"){
            if(v[j]) {
              v[j] = moment(v[j]).format("DD/MM/YYYY")
            } else{
              v[j] = "Refer Seller"
            }
            return v[j]
            
          }
          else {
            return v[j] ? v[j] : "Refer Seller";
          }
        })
      );
    },
    clearFields() {
      this.fileName = "";
      this.cellAutoWidth = true;
      this.selectedFormat = "csv";
    },

  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
    csvData: function(){
      return this.logs.map(log => {
        return {
          type: log.type,
          createdAt: moment(log.createdAt).format('DD/MM/YYYY HH:mm A'),
          model: log.carId.model,
          make: log.carId.make,
          year: log.carId.year,
          series: log.carId.series?log.carId.series:"Refer Seller",
          bodyType: log.carId.bodyType?log.carId.bodyType:"Refer Seller",
          transmission: log.carId.transmission?log.carId.transmission:"Refer Seller",
          badge: log.carId.badge? log.carId.badge:"Refer Seller",
          cylinder: log.carId.cylinder?log.carId.cylinder:"Refer Seller",
          engineCapacity: log.carId.engineCapacity?log.carId.engineCapacity:"Refer Seller",
          askingPrice: log.carId.askingPrice,
          kilometer: log.carId.kilometer,
          drv: log.carId.drv ? log.carId.drv : "Refer Seller",
          buyerName: log.name,
          buyerEmail: log.email,
          buyerPhone: log.phoneNumber,
          ownerName: log.dealerId.name,
          ownerEmail: log.dealerId.contactInformation.email,
          ownerMobile: log.dealerId.contactInformation.contactPhone,
          ownerTelephone: log.dealerId.contactInformation.contactTelePhone,
          ownerAddress: log.dealerId.displayAddress,
          ownerLicenseNumber: log.dealerId.licenseNumber,
          stockNumber: log.carId.stockNumber?log.carId.stockNumber:"Refer Seller",
          ownerId: log.dealerId.dealerID
        }
      })
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateLogPage(newVal);
        this.dataTableParams.page = newVal;
        this.getLogList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.updateLogPageLimit(newlimit)
        // this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getLogList();
      }
    },
    "dataTableParams.search": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = 1;
        this.updateLogPageSearch(newVal)
        this.dataTableParams.search = newVal;
        this.getLogList();
      }
    },
  },
  created() {
    this.initToFirstPage({pageType: "log"});
    this.getLogList();
  },
};
</script>
